
import './App.css';
import {BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import {AuthProvider} from './context/AuthContext'
import { Login } from './pages/Login';

function App() {
  return (
    <AuthProvider>
    <Router>
      
       <div className="container-sm pt-4">
     
        <Routes>
          <Route path="/about" element={<h1>About</h1>}></Route>
          <Route path="/" element={<Login/>}> </Route>
        
        </Routes>
      </div>
    
    </Router>
 </AuthProvider>
  );
}

export default App;
