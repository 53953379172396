import axios from "./axios";
const API=process.env.REACT_APP_API

export const validateUser = async (user) =>{ 
    
    axios.post(`/auth/validate`, user);
    
}

export const verifyTokenRequest = async () => axios.get(`/auth/verify`);
export const validateUserRequest = async (user) => axios.post(`auth/validate`,user);
export const validateUserRequest1=async (user)=>{
    console.log('password ' +user.password)
    const resp=await fetch(`${API}/auth/validate`,{
        method:'POST',
        mode: 'cors',
        headers:{
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'username':user.username,
            'password':user.password
            
            })          
    })
  
    return resp

}