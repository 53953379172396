import { createContext,useState,useContext } from "react"
import { validateUserRequest } from "../api/auth";
const AuthContext = createContext()

export const useAuth=()=>{
    const context=useContext(AuthContext);
    if(!context){
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}




export const AuthProvider =({children})=>{
    const [user,setUser]=useState(null)
    const [isAuthenticated,setAuthenticate]=useState(false)
    const signup=async (user)=>{
        
        try {
            const res = await validateUserRequest(user);
            const data = await res.json()
            setUser(data);
            
            setAuthenticate(true)
          } catch (error) {
            console.log('error en fetch',error);
            // setErrors(error.response.data.message);
          }
   

    }
   
    const signout=()=>{
        setAuthenticate(false)
    }
    return (
        <AuthContext.Provider 
        value={{
            user,
            signup,
            signout,
            
            isAuthenticated,

        }}>
            {children}
        </AuthContext.Provider>
    )
}