import React,{useEffect, useState} from 'react'
import {useForm} from "react-hook-form"
import {useNavigate } from 'react-router-dom'
import {useAuth} from '../context/AuthContext'
import './login.css'
import logo from '../usuario.png';
const API=process.env.REACT_APP_API
export const Login = () => {
  const {isAuthenticated,signup,signout}=useAuth()
  const [validado,setValidado]=useState(true)

  const navigate=useNavigate()
  const {register,handleSubmit,
        formState:{errors}
        }=useForm();


  const onSubmit=handleSubmit((data)=>{
      if(data.username==='admin58' && data.password==='admin85'){
     
        setValidado(true)
        signup(data)
        //validaruser(data)

      }else{
       
        setValidado(false)
        signout()
      
      }
  })
  useEffect(()=>{
      if(isAuthenticated){
      
        navigate('/user')
      }
    },[isAuthenticated,])
    useEffect(()=>{
   
      if(!validado){
        const timer=setTimeout(()=>{
          setValidado(true)
        },5000)
        return()=> clearTimeout(timer)
      }
    },[validado])

    const validaruser = async (user)=>{

      const resp= await fetch(`${API}/auth/validate`,{
          method:'POST',
          mode: 'cors',
          headers:{
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          'username':user.username,
          'password':user.password
              
              })          
      })
      const data= await resp.json().catch(e=>{console.log('error en fetch', e)})
      console.log(data)
  
  
  }
  return (
    <div className="container">
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h2 className="text-center text-dark mt-5">ITEFSA</h2>

        <div className="card my-5">

          <form className="card-body cardbody-color p-lg-5" onSubmit={onSubmit} >

            <div className="text-center">
              <img src={logo} className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                width="200px" alt="profile" />
            </div>

            <div className="mb-3">
              <input type="text" className="form-control" id="Username" aria-describedby="emailHelp"
                placeholder="usuario"
                {...register('username',{required:{
                  value:true,
                  message:'Usuario es requerido'
                },
                maxLength:{
                  value:10,
                  message:'Usuario no puede tener mas de 10 caracteres'
                },
                
                minLength:{
                  value:4,
                  message:'Usuario no puede tener menos de 4 caracteres'
                }
              })} 
                />
            </div>
            <div className="mb-3">
              <input type="password" className="form-control" id="password" placeholder="contraseña"
               {...register('password',{required:true})} 
              />
            </div>
          
              <div className="text-center"><button type="submit" className="btn btn-success px-5 mb-5 w-100">Ingresar</button></div>
              {errors.username && <span className='text-danger text-small d-block mb-2'>{errors.username.message}</span>}
              {errors.password && <span className='text-danger text-small d-block mb-2'>{errors.password.message}</span>}
              {!validado && <span className='text-danger text-small d-block mb-2'>Usuario o Contraseña incorrectos</span>}
          </form>
        </div>

      </div>
    </div>
  </div>
  )
}
